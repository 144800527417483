<template>
	<div>
		<div class="fix">
			<div class="side-info">
				<button class="side-info-close"><i class="fal fa-times"></i></button>
				<div class="side-info-content">
					<div class="mobile-menu"></div>
				</div>
			</div>
		</div>
		<div class="offcanvas-overlay"></div>

		<!-- page title start  -->
		<section class="page__title  fix text-center">
			<div class="slider__shape">
				<img class="shape triangle" src="/static/picture/topelips.png" alt="topshape">
				<img class="shape dotted-square" src="/static/picture/rightelips.png" alt="rightshape">
				<img class="shape solid-square" src="/static/picture/circleelips.png" alt="solid-square">
			</div>
			<div class="container">
				<div class="row">
					<div class="col-xl-12">
						<div class="page__title-content pt-170">
							<h2 class="page_title">关于我们</h2>
							<nav aria-label="breadcrumb">
								<ol class="breadcrumb">
									<p style="color: white">
										微行网络科技，致力于打造旅游及生活服务的爆品新零售电商平台，专注于社交模式创销与爆款<br/>
										的打造，整合精准流量式营销，为旅游及生活娱乐等产品提供流量的解决方案；深度围绕全球旅<br/>
										游、机票、酒店、景区、度假村等项目，提供销售渠道、推广、包装为一体的解决方案。</p>
								</ol>
							</nav>
						</div>
					</div>
				</div>
			</div>
		</section>
		<!-- page title end  -->

		<div class="container" style="text-align: center;margin-top: 32px">
			<img src="/static/picture/about2bg01.jpg" style="max-height: 300px; width: 800px">
		</div>

		<div class="container">
			<div style="text-align: center; margin-bottom: 32px;display: inline">
				<h2 style="margin-top: 32px">微行学堂</h2>
				<p>
					微行学堂的诞生，旨在把握中国经济持续快速增长的大好机遇，通过“取势、明道、优术、践<br/>
					行、合众” 的战略选择和“同心、同行、同乐”的办学理念，为中国打造一个旅游社交电商的学<br/>
					堂。通过学习和实战，培养并打造一大批旅游电商新服务精英领袖，并为中国旅游企业进军电<br/>
					商提供新思维、新视野和新动力。
				</p>
				<p>
					<br/>
					微行CEO曾顿先生说：杰出并非一种天赋，而是人人都可以学习的技巧。
					<br/>
					<br/>
				</p>
				<p>
					微行学堂希望为每一位伙伴们提供一把金钥匙，协助打造学习型团队；以“共享新知识新服务智<br/>
					慧，培养旅游社交电商领袖人才”为使命，致力成为最懂用户需求与服务的一流旅游社交学堂。
				</p>
			</div>
		</div>

		<!-- contact form Start  -->
		<div class="contact__wrapper mt-115 mb-80">
			<div class="container">
				<div class="row">
					<div class="col-xl-4 col-lg-4 col-md-4">
						<div class="contact__info">
							<h4 class="info_title">办公地址：</h4>
							<p>广东省深圳市罗湖区宝安北路宝能慧谷C座16-19</p>
						</div>
						<div class="contact__form pt-70 pb-50">
							<h4>联系方式：</h4>
							<ul>
								<!--								<li>电话 :  -</li>-->
								<li>邮箱 : go@weseego.com</li>
							</ul>
						</div>
					</div>
					<div class="col-xl-8 col-lg-8 col-md-8">
						<div class="post-comment-form contact-form">
							<form action="#">
								<div class="row">
									<div class="col-xl-6 col-md-6">
										<div class="post-input contact-form">
											<input type="text" placeholder="你输入你的名称">
										</div>
									</div>
									<div class="col-xl-6 col-md-6">
										<div class="post-input contact-form">
											<input type="email" placeholder="请输入你的联系邮箱">
										</div>
									</div>
									<div class="col-xl-12 col-lg-12 col-md-12">
										<div class="post-input contact-form">
											<input type="text" placeholder="你输入主题"/>
										</div>
									</div>
									<div class="col-xl-12">
										<div class="post-input contact-form">
											<textarea placeholder="请输入信息"></textarea>
										</div>
									</div>
									<div class="col-xl-4">
										<div class="post-check mb-40">
											<button  class="btn btn-comment" style="width: 200px; border-color: #dee2e6"  @click="onClickSubmit">提交</button>
										</div>
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- contact form End  -->


		<remote-script src="/static/js/main.js"></remote-script>

	</div>
</template>

<script>
	import '@/utils/importJs.js'

	export default {
		components: {},
		data() {
			return {}
		},
		methods: {
			onClickSubmit() {
				console.log("提交")
				this.$message.success("已提交");
			}
		}
	}
</script>

<style scoped>

</style>